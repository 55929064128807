export function useTheme() {
  const fontSizeCookie = useCookie<number | null>("fontSize");
  const contrastCookie = useCookie<number | null>("contrastTheme");

  const fontSize = computed(() => {
    if (fontSizeCookie.value === 1) {
      return "font-size-1";
    } else if (fontSizeCookie.value === 2) {
      return "font-size-2";
    } else if (fontSizeCookie.value === 3) {
      return "font-size-3";
    }
    return undefined;
  });

  const contrast = computed(() => {
    if (contrastCookie.value === 1) {
      return "contrast-1";
    } else if (contrastCookie.value === 2) {
      return "contrast-2";
    } else if (contrastCookie.value === 3) {
      return "contrast-3";
    }
    return undefined;
  });

  const themeClasses = computed(() => {
    let classes: string | undefined = "";
    if (fontSize.value) {
      classes += fontSize.value;
    }
    if (contrast.value) {
      if (classes !== "") {
        classes += " ";
      }
      classes += contrast.value;
    }
    if (classes === "") {
      classes = undefined;
    }

    return classes;
  });

  function setContrast(contrastThemeNumber: number | null) {
    contrastCookie.value = contrastThemeNumber;
    setTheme();
  }

  function setFontSize(fontSizeNumber: number | null) {
    fontSizeCookie.value = fontSizeNumber;
    setTheme();
  }

  function setTheme() {
    document.documentElement.className = themeClasses.value ?? "";
  }

  useHead({
    htmlAttrs: {
      class: themeClasses.value ?? "",
    },
  });

  return {
    themeClasses,
    setFontSize,
    setContrast,
    fontSize,
    contrast,
  };
}
