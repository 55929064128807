import { default as acceptedStatisticsHYz9dTaiTYMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/acceptedStatistics.vue?macro=true";
import { default as accountRemovedNqD784l02OMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/accountRemoved.vue?macro=true";
import { default as declarationKrGdxRrkiGMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/declaration.vue?macro=true";
import { default as devDomainoUHBqTz1M3Meta } from "/var/lib/jenkins/workspace/PZO_master/client/common-components-nuxt/pages/devDomain.vue?macro=true";
import { default as formH61avHvSE4Meta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/form.vue?macro=true";
import { default as _91current_933TtOifc3VtMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/freePlaces/[current].vue?macro=true";
import { default as index2Q1iwd91fjMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/index.vue?macro=true";
import { default as instructionFilesW9pwT5xGjCMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/instructionFiles.vue?macro=true";
import { default as loginuW5uZuCplJMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/login.vue?macro=true";
import { default as loginMMError8DkPCcMrSSMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/loginMMError.vue?macro=true";
import { default as loginMMSuccess72gWXIxH3rMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/loginMMSuccess.vue?macro=true";
import { default as loginWkErrorP1iz2nOxwfMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/loginWkError.vue?macro=true";
import { default as loginWkSuccessiXaXpo0MQ4Meta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/loginWkSuccess.vue?macro=true";
import { default as indexGMsgkEsP6rMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/menu/[id]/index.vue?macro=true";
import { default as minimalPointsE9N6uosRfDMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/minimalPoints.vue?macro=true";
import { default as noscriptlq7EKSo9EqMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/noscript.vue?macro=true";
import { default as notSupportedBrowserCyAG4i2ZVbMeta } from "/var/lib/jenkins/workspace/PZO_master/client/common-components-nuxt/pages/notSupportedBrowser.vue?macro=true";
import { default as index4MjkBheSJqMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/offer/search/index.vue?macro=true";
import { default as resultshHLuAbwHnqMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/offer/search/results.vue?macro=true";
import { default as passwordReset9kNmHkJoSFMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/passwordReset.vue?macro=true";
import { default as pointsSimulatorIDZ6CbCrO6Meta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/pointsSimulator.vue?macro=true";
import { default as searchlwkV2YjEmNMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/region/search.vue?macro=true";
import { default as registert0cSp0wsJaMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/register.vue?macro=true";
import { default as resetPasswordllYchsP4vqMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/resetPassword.vue?macro=true";
import { default as schedulebL6KfNYiadMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/schedule.vue?macro=true";
import { default as sessionTimeoutlz03ip7RMJMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/sessionTimeout.vue?macro=true";
import { default as sitemap3KNhZqk6mjMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/sitemap.vue?macro=true";
import { default as statisticsB5BsXSW2D7Meta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/statistics.vue?macro=true";
import { default as unauthorizedeKeLjGoqcNMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/unauthorized.vue?macro=true";
import { default as accountSettinguryzeCcgoRMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/accountSetting.vue?macro=true";
import { default as appealNewcT28iRfgvSMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/appealNew.vue?macro=true";
import { default as appealsJ0q88kkeR0Meta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/appeals.vue?macro=true";
import { default as appealViewm3Ra5um5QMMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/appealView.vue?macro=true";
import { default as applicationsB0pWbmORjzMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/applications.vue?macro=true";
import { default as applicationSaveStatus2hhxnJ7waiMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/applicationSaveStatus.vue?macro=true";
import { default as attachmentsFBcIKvfOiFMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/attachments.vue?macro=true";
import { default as history40DPeLRTUfMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/history.vue?macro=true";
import { default as indexojjepQuzZvMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/index.vue?macro=true";
import { default as linkStudentBLl6lIgwxxMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/linkStudent.vue?macro=true";
import { default as loggedInyRnXNmfHOFMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/loggedIn.vue?macro=true";
import { default as qualificationCardeIKhMUHX6pMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/qualificationCard.vue?macro=true";
import { default as signApplicationCancelI5YbkWcpuqMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/signApplicationCancel.vue?macro=true";
import { default as signApplicationFailedFk6UCWAJl7Meta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/signApplicationFailed.vue?macro=true";
import { default as signApplicationSuccess17YSFMQrx2Meta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/signApplicationSuccess.vue?macro=true";
import { default as signQualificationCardCancelS1PQJJMc1xMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/signQualificationCardCancel.vue?macro=true";
import { default as signQualificationCardFailed2tyM2yashsMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/signQualificationCardFailed.vue?macro=true";
import { default as signQualificationCardSuccessy8eH3FBkuDMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/signQualificationCardSuccess.vue?macro=true";
import { default as temporaryPasswordltVSEXH5yqMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/temporaryPassword.vue?macro=true";
import { default as testsMJWgjxuibTMeta } from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/tests.vue?macro=true";
export default [
  {
    name: acceptedStatisticsHYz9dTaiTYMeta?.name ?? "acceptedStatistics",
    path: acceptedStatisticsHYz9dTaiTYMeta?.path ?? "/acceptedStatistics",
    meta: acceptedStatisticsHYz9dTaiTYMeta || {},
    alias: acceptedStatisticsHYz9dTaiTYMeta?.alias || [],
    redirect: acceptedStatisticsHYz9dTaiTYMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/acceptedStatistics.vue").then(m => m.default || m)
  },
  {
    name: accountRemovedNqD784l02OMeta?.name ?? "accountRemoved",
    path: accountRemovedNqD784l02OMeta?.path ?? "/accountRemoved",
    meta: accountRemovedNqD784l02OMeta || {},
    alias: accountRemovedNqD784l02OMeta?.alias || [],
    redirect: accountRemovedNqD784l02OMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/accountRemoved.vue").then(m => m.default || m)
  },
  {
    name: declarationKrGdxRrkiGMeta?.name ?? "declaration",
    path: declarationKrGdxRrkiGMeta?.path ?? "/declaration",
    meta: declarationKrGdxRrkiGMeta || {},
    alias: declarationKrGdxRrkiGMeta?.alias || [],
    redirect: declarationKrGdxRrkiGMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/declaration.vue").then(m => m.default || m)
  },
  {
    name: devDomainoUHBqTz1M3Meta?.name ?? "devDomain",
    path: devDomainoUHBqTz1M3Meta?.path ?? "/devDomain",
    meta: devDomainoUHBqTz1M3Meta || {},
    alias: devDomainoUHBqTz1M3Meta?.alias || [],
    redirect: devDomainoUHBqTz1M3Meta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/common-components-nuxt/pages/devDomain.vue").then(m => m.default || m)
  },
  {
    name: formH61avHvSE4Meta?.name ?? "form",
    path: formH61avHvSE4Meta?.path ?? "/form",
    meta: formH61avHvSE4Meta || {},
    alias: formH61avHvSE4Meta?.alias || [],
    redirect: formH61avHvSE4Meta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/form.vue").then(m => m.default || m)
  },
  {
    name: _91current_933TtOifc3VtMeta?.name ?? "freePlaces-current",
    path: _91current_933TtOifc3VtMeta?.path ?? "/freePlaces/:current()",
    meta: _91current_933TtOifc3VtMeta || {},
    alias: _91current_933TtOifc3VtMeta?.alias || [],
    redirect: _91current_933TtOifc3VtMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/freePlaces/[current].vue").then(m => m.default || m)
  },
  {
    name: index2Q1iwd91fjMeta?.name ?? "index",
    path: index2Q1iwd91fjMeta?.path ?? "/",
    meta: index2Q1iwd91fjMeta || {},
    alias: index2Q1iwd91fjMeta?.alias || [],
    redirect: index2Q1iwd91fjMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/index.vue").then(m => m.default || m)
  },
  {
    name: instructionFilesW9pwT5xGjCMeta?.name ?? "instructionFiles",
    path: instructionFilesW9pwT5xGjCMeta?.path ?? "/instructionFiles",
    meta: instructionFilesW9pwT5xGjCMeta || {},
    alias: instructionFilesW9pwT5xGjCMeta?.alias || [],
    redirect: instructionFilesW9pwT5xGjCMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/instructionFiles.vue").then(m => m.default || m)
  },
  {
    name: loginuW5uZuCplJMeta?.name ?? "login",
    path: loginuW5uZuCplJMeta?.path ?? "/login",
    meta: loginuW5uZuCplJMeta || {},
    alias: loginuW5uZuCplJMeta?.alias || [],
    redirect: loginuW5uZuCplJMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginMMError8DkPCcMrSSMeta?.name ?? "loginMMError",
    path: loginMMError8DkPCcMrSSMeta?.path ?? "/loginMMError",
    meta: loginMMError8DkPCcMrSSMeta || {},
    alias: loginMMError8DkPCcMrSSMeta?.alias || [],
    redirect: loginMMError8DkPCcMrSSMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/loginMMError.vue").then(m => m.default || m)
  },
  {
    name: loginMMSuccess72gWXIxH3rMeta?.name ?? "loginMMSuccess",
    path: loginMMSuccess72gWXIxH3rMeta?.path ?? "/loginMMSuccess",
    meta: loginMMSuccess72gWXIxH3rMeta || {},
    alias: loginMMSuccess72gWXIxH3rMeta?.alias || [],
    redirect: loginMMSuccess72gWXIxH3rMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/loginMMSuccess.vue").then(m => m.default || m)
  },
  {
    name: loginWkErrorP1iz2nOxwfMeta?.name ?? "loginWkError",
    path: loginWkErrorP1iz2nOxwfMeta?.path ?? "/loginWkError",
    meta: loginWkErrorP1iz2nOxwfMeta || {},
    alias: loginWkErrorP1iz2nOxwfMeta?.alias || [],
    redirect: loginWkErrorP1iz2nOxwfMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/loginWkError.vue").then(m => m.default || m)
  },
  {
    name: loginWkSuccessiXaXpo0MQ4Meta?.name ?? "loginWkSuccess",
    path: loginWkSuccessiXaXpo0MQ4Meta?.path ?? "/loginWkSuccess",
    meta: loginWkSuccessiXaXpo0MQ4Meta || {},
    alias: loginWkSuccessiXaXpo0MQ4Meta?.alias || [],
    redirect: loginWkSuccessiXaXpo0MQ4Meta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/loginWkSuccess.vue").then(m => m.default || m)
  },
  {
    name: indexGMsgkEsP6rMeta?.name ?? "menu-id",
    path: indexGMsgkEsP6rMeta?.path ?? "/menu/:id()",
    meta: indexGMsgkEsP6rMeta || {},
    alias: indexGMsgkEsP6rMeta?.alias || [],
    redirect: indexGMsgkEsP6rMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/menu/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: minimalPointsE9N6uosRfDMeta?.name ?? "minimalPoints",
    path: minimalPointsE9N6uosRfDMeta?.path ?? "/minimalPoints",
    meta: minimalPointsE9N6uosRfDMeta || {},
    alias: minimalPointsE9N6uosRfDMeta?.alias || [],
    redirect: minimalPointsE9N6uosRfDMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/minimalPoints.vue").then(m => m.default || m)
  },
  {
    name: noscriptlq7EKSo9EqMeta?.name ?? "noscript",
    path: noscriptlq7EKSo9EqMeta?.path ?? "/noscript",
    meta: noscriptlq7EKSo9EqMeta || {},
    alias: noscriptlq7EKSo9EqMeta?.alias || [],
    redirect: noscriptlq7EKSo9EqMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/noscript.vue").then(m => m.default || m)
  },
  {
    name: notSupportedBrowserCyAG4i2ZVbMeta?.name ?? "notSupportedBrowser",
    path: notSupportedBrowserCyAG4i2ZVbMeta?.path ?? "/notSupportedBrowser",
    meta: notSupportedBrowserCyAG4i2ZVbMeta || {},
    alias: notSupportedBrowserCyAG4i2ZVbMeta?.alias || [],
    redirect: notSupportedBrowserCyAG4i2ZVbMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/common-components-nuxt/pages/notSupportedBrowser.vue").then(m => m.default || m)
  },
  {
    name: index4MjkBheSJqMeta?.name ?? "offer-search",
    path: index4MjkBheSJqMeta?.path ?? "/offer/search",
    meta: index4MjkBheSJqMeta || {},
    alias: index4MjkBheSJqMeta?.alias || [],
    redirect: index4MjkBheSJqMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/offer/search/index.vue").then(m => m.default || m)
  },
  {
    name: resultshHLuAbwHnqMeta?.name ?? "offer-search-results",
    path: resultshHLuAbwHnqMeta?.path ?? "/offer/search/results",
    meta: resultshHLuAbwHnqMeta || {},
    alias: resultshHLuAbwHnqMeta?.alias || [],
    redirect: resultshHLuAbwHnqMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/offer/search/results.vue").then(m => m.default || m)
  },
  {
    name: passwordReset9kNmHkJoSFMeta?.name ?? "passwordReset",
    path: passwordReset9kNmHkJoSFMeta?.path ?? "/passwordReset",
    meta: passwordReset9kNmHkJoSFMeta || {},
    alias: passwordReset9kNmHkJoSFMeta?.alias || [],
    redirect: passwordReset9kNmHkJoSFMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/passwordReset.vue").then(m => m.default || m)
  },
  {
    name: pointsSimulatorIDZ6CbCrO6Meta?.name ?? "pointsSimulator",
    path: pointsSimulatorIDZ6CbCrO6Meta?.path ?? "/pointsSimulator",
    meta: pointsSimulatorIDZ6CbCrO6Meta || {},
    alias: pointsSimulatorIDZ6CbCrO6Meta?.alias || [],
    redirect: pointsSimulatorIDZ6CbCrO6Meta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/pointsSimulator.vue").then(m => m.default || m)
  },
  {
    name: searchlwkV2YjEmNMeta?.name ?? "region-search",
    path: searchlwkV2YjEmNMeta?.path ?? "/region/search",
    meta: searchlwkV2YjEmNMeta || {},
    alias: searchlwkV2YjEmNMeta?.alias || [],
    redirect: searchlwkV2YjEmNMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/region/search.vue").then(m => m.default || m)
  },
  {
    name: registert0cSp0wsJaMeta?.name ?? "register",
    path: registert0cSp0wsJaMeta?.path ?? "/register",
    meta: registert0cSp0wsJaMeta || {},
    alias: registert0cSp0wsJaMeta?.alias || [],
    redirect: registert0cSp0wsJaMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/register.vue").then(m => m.default || m)
  },
  {
    name: resetPasswordllYchsP4vqMeta?.name ?? "resetPassword",
    path: resetPasswordllYchsP4vqMeta?.path ?? "/resetPassword",
    meta: resetPasswordllYchsP4vqMeta || {},
    alias: resetPasswordllYchsP4vqMeta?.alias || [],
    redirect: resetPasswordllYchsP4vqMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/resetPassword.vue").then(m => m.default || m)
  },
  {
    name: schedulebL6KfNYiadMeta?.name ?? "schedule",
    path: schedulebL6KfNYiadMeta?.path ?? "/schedule",
    meta: schedulebL6KfNYiadMeta || {},
    alias: schedulebL6KfNYiadMeta?.alias || [],
    redirect: schedulebL6KfNYiadMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/schedule.vue").then(m => m.default || m)
  },
  {
    name: sessionTimeoutlz03ip7RMJMeta?.name ?? "sessionTimeout",
    path: sessionTimeoutlz03ip7RMJMeta?.path ?? "/sessionTimeout",
    meta: sessionTimeoutlz03ip7RMJMeta || {},
    alias: sessionTimeoutlz03ip7RMJMeta?.alias || [],
    redirect: sessionTimeoutlz03ip7RMJMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/sessionTimeout.vue").then(m => m.default || m)
  },
  {
    name: sitemap3KNhZqk6mjMeta?.name ?? "sitemap",
    path: sitemap3KNhZqk6mjMeta?.path ?? "/sitemap",
    meta: sitemap3KNhZqk6mjMeta || {},
    alias: sitemap3KNhZqk6mjMeta?.alias || [],
    redirect: sitemap3KNhZqk6mjMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: statisticsB5BsXSW2D7Meta?.name ?? "statistics",
    path: statisticsB5BsXSW2D7Meta?.path ?? "/statistics",
    meta: statisticsB5BsXSW2D7Meta || {},
    alias: statisticsB5BsXSW2D7Meta?.alias || [],
    redirect: statisticsB5BsXSW2D7Meta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/statistics.vue").then(m => m.default || m)
  },
  {
    name: unauthorizedeKeLjGoqcNMeta?.name ?? "unauthorized",
    path: unauthorizedeKeLjGoqcNMeta?.path ?? "/unauthorized",
    meta: unauthorizedeKeLjGoqcNMeta || {},
    alias: unauthorizedeKeLjGoqcNMeta?.alias || [],
    redirect: unauthorizedeKeLjGoqcNMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/unauthorized.vue").then(m => m.default || m)
  },
  {
    name: accountSettinguryzeCcgoRMeta?.name ?? "user-accountSetting",
    path: accountSettinguryzeCcgoRMeta?.path ?? "/user/accountSetting",
    meta: accountSettinguryzeCcgoRMeta || {},
    alias: accountSettinguryzeCcgoRMeta?.alias || [],
    redirect: accountSettinguryzeCcgoRMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/accountSetting.vue").then(m => m.default || m)
  },
  {
    name: appealNewcT28iRfgvSMeta?.name ?? "user-appealNew",
    path: appealNewcT28iRfgvSMeta?.path ?? "/user/appealNew",
    meta: appealNewcT28iRfgvSMeta || {},
    alias: appealNewcT28iRfgvSMeta?.alias || [],
    redirect: appealNewcT28iRfgvSMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/appealNew.vue").then(m => m.default || m)
  },
  {
    name: appealsJ0q88kkeR0Meta?.name ?? "user-appeals",
    path: appealsJ0q88kkeR0Meta?.path ?? "/user/appeals",
    meta: appealsJ0q88kkeR0Meta || {},
    alias: appealsJ0q88kkeR0Meta?.alias || [],
    redirect: appealsJ0q88kkeR0Meta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/appeals.vue").then(m => m.default || m)
  },
  {
    name: appealViewm3Ra5um5QMMeta?.name ?? "user-appealView",
    path: appealViewm3Ra5um5QMMeta?.path ?? "/user/appealView",
    meta: appealViewm3Ra5um5QMMeta || {},
    alias: appealViewm3Ra5um5QMMeta?.alias || [],
    redirect: appealViewm3Ra5um5QMMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/appealView.vue").then(m => m.default || m)
  },
  {
    name: applicationsB0pWbmORjzMeta?.name ?? "user-applications",
    path: applicationsB0pWbmORjzMeta?.path ?? "/user/applications",
    meta: applicationsB0pWbmORjzMeta || {},
    alias: applicationsB0pWbmORjzMeta?.alias || [],
    redirect: applicationsB0pWbmORjzMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/applications.vue").then(m => m.default || m)
  },
  {
    name: applicationSaveStatus2hhxnJ7waiMeta?.name ?? "user-applicationSaveStatus",
    path: applicationSaveStatus2hhxnJ7waiMeta?.path ?? "/user/applicationSaveStatus",
    meta: applicationSaveStatus2hhxnJ7waiMeta || {},
    alias: applicationSaveStatus2hhxnJ7waiMeta?.alias || [],
    redirect: applicationSaveStatus2hhxnJ7waiMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/applicationSaveStatus.vue").then(m => m.default || m)
  },
  {
    name: attachmentsFBcIKvfOiFMeta?.name ?? "user-attachments",
    path: attachmentsFBcIKvfOiFMeta?.path ?? "/user/attachments",
    meta: attachmentsFBcIKvfOiFMeta || {},
    alias: attachmentsFBcIKvfOiFMeta?.alias || [],
    redirect: attachmentsFBcIKvfOiFMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/attachments.vue").then(m => m.default || m)
  },
  {
    name: history40DPeLRTUfMeta?.name ?? "user-history",
    path: history40DPeLRTUfMeta?.path ?? "/user/history",
    meta: history40DPeLRTUfMeta || {},
    alias: history40DPeLRTUfMeta?.alias || [],
    redirect: history40DPeLRTUfMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/history.vue").then(m => m.default || m)
  },
  {
    name: indexojjepQuzZvMeta?.name ?? "user",
    path: indexojjepQuzZvMeta?.path ?? "/user",
    meta: indexojjepQuzZvMeta || {},
    alias: indexojjepQuzZvMeta?.alias || [],
    redirect: indexojjepQuzZvMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: linkStudentBLl6lIgwxxMeta?.name ?? "user-linkStudent",
    path: linkStudentBLl6lIgwxxMeta?.path ?? "/user/linkStudent",
    meta: linkStudentBLl6lIgwxxMeta || {},
    alias: linkStudentBLl6lIgwxxMeta?.alias || [],
    redirect: linkStudentBLl6lIgwxxMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/linkStudent.vue").then(m => m.default || m)
  },
  {
    name: loggedInyRnXNmfHOFMeta?.name ?? "user-loggedIn",
    path: loggedInyRnXNmfHOFMeta?.path ?? "/user/loggedIn",
    meta: loggedInyRnXNmfHOFMeta || {},
    alias: loggedInyRnXNmfHOFMeta?.alias || [],
    redirect: loggedInyRnXNmfHOFMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/loggedIn.vue").then(m => m.default || m)
  },
  {
    name: qualificationCardeIKhMUHX6pMeta?.name ?? "user-qualificationCard",
    path: qualificationCardeIKhMUHX6pMeta?.path ?? "/user/qualificationCard",
    meta: qualificationCardeIKhMUHX6pMeta || {},
    alias: qualificationCardeIKhMUHX6pMeta?.alias || [],
    redirect: qualificationCardeIKhMUHX6pMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/qualificationCard.vue").then(m => m.default || m)
  },
  {
    name: signApplicationCancelI5YbkWcpuqMeta?.name ?? "user-signApplicationCancel",
    path: signApplicationCancelI5YbkWcpuqMeta?.path ?? "/user/signApplicationCancel",
    meta: signApplicationCancelI5YbkWcpuqMeta || {},
    alias: signApplicationCancelI5YbkWcpuqMeta?.alias || [],
    redirect: signApplicationCancelI5YbkWcpuqMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/signApplicationCancel.vue").then(m => m.default || m)
  },
  {
    name: signApplicationFailedFk6UCWAJl7Meta?.name ?? "user-signApplicationFailed",
    path: signApplicationFailedFk6UCWAJl7Meta?.path ?? "/user/signApplicationFailed",
    meta: signApplicationFailedFk6UCWAJl7Meta || {},
    alias: signApplicationFailedFk6UCWAJl7Meta?.alias || [],
    redirect: signApplicationFailedFk6UCWAJl7Meta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/signApplicationFailed.vue").then(m => m.default || m)
  },
  {
    name: signApplicationSuccess17YSFMQrx2Meta?.name ?? "user-signApplicationSuccess",
    path: signApplicationSuccess17YSFMQrx2Meta?.path ?? "/user/signApplicationSuccess",
    meta: signApplicationSuccess17YSFMQrx2Meta || {},
    alias: signApplicationSuccess17YSFMQrx2Meta?.alias || [],
    redirect: signApplicationSuccess17YSFMQrx2Meta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/signApplicationSuccess.vue").then(m => m.default || m)
  },
  {
    name: signQualificationCardCancelS1PQJJMc1xMeta?.name ?? "user-signQualificationCardCancel",
    path: signQualificationCardCancelS1PQJJMc1xMeta?.path ?? "/user/signQualificationCardCancel",
    meta: signQualificationCardCancelS1PQJJMc1xMeta || {},
    alias: signQualificationCardCancelS1PQJJMc1xMeta?.alias || [],
    redirect: signQualificationCardCancelS1PQJJMc1xMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/signQualificationCardCancel.vue").then(m => m.default || m)
  },
  {
    name: signQualificationCardFailed2tyM2yashsMeta?.name ?? "user-signQualificationCardFailed",
    path: signQualificationCardFailed2tyM2yashsMeta?.path ?? "/user/signQualificationCardFailed",
    meta: signQualificationCardFailed2tyM2yashsMeta || {},
    alias: signQualificationCardFailed2tyM2yashsMeta?.alias || [],
    redirect: signQualificationCardFailed2tyM2yashsMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/signQualificationCardFailed.vue").then(m => m.default || m)
  },
  {
    name: signQualificationCardSuccessy8eH3FBkuDMeta?.name ?? "user-signQualificationCardSuccess",
    path: signQualificationCardSuccessy8eH3FBkuDMeta?.path ?? "/user/signQualificationCardSuccess",
    meta: signQualificationCardSuccessy8eH3FBkuDMeta || {},
    alias: signQualificationCardSuccessy8eH3FBkuDMeta?.alias || [],
    redirect: signQualificationCardSuccessy8eH3FBkuDMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/signQualificationCardSuccess.vue").then(m => m.default || m)
  },
  {
    name: temporaryPasswordltVSEXH5yqMeta?.name ?? "user-temporaryPassword",
    path: temporaryPasswordltVSEXH5yqMeta?.path ?? "/user/temporaryPassword",
    meta: temporaryPasswordltVSEXH5yqMeta || {},
    alias: temporaryPasswordltVSEXH5yqMeta?.alias || [],
    redirect: temporaryPasswordltVSEXH5yqMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/temporaryPassword.vue").then(m => m.default || m)
  },
  {
    name: testsMJWgjxuibTMeta?.name ?? "user-tests",
    path: testsMJWgjxuibTMeta?.path ?? "/user/tests",
    meta: testsMJWgjxuibTMeta || {},
    alias: testsMJWgjxuibTMeta?.alias || [],
    redirect: testsMJWgjxuibTMeta?.redirect,
    component: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/pages/user/tests.vue").then(m => m.default || m)
  }
]