<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="flex h-screen flex-col items-center gap-4 p-4 md:place-content-center bg-base-2"
  >
    <div
      v-if="!registeredError || !registeredError.registeredException"
      class="flex max-w-screen-lg flex-col rounded border border-neutral-7 contrast:border-base-1 p-4 shadow-2xl"
    >
      <h1 class="w-128">
        {{ localization.errorOccuredNotRegistered }} {{ error.statusCode }}
      </h1>
      <HorizontalSeparator class="my-4" />
      <p
        v-if="error.data && typeof error.data !== 'string' && error.data.title"
      >
        {{ error.data.title }}
      </p>
      <p v-else>{{ error.message }}</p>
      <ControlPanel>
        <ControlButton
          id="back_to_main_page"
          :inverted="false"
          @click="handleError"
        >
          {{ localization.back }}
        </ControlButton>
      </ControlPanel>
    </div>
    <div
      v-else
      class="flex max-w-screen-lg flex-col rounded border border-neutral-7 contrast:border-base-1 p-4 shadow-2xl"
    >
      <h1>
        {{ localization.errorOccured }}
        {{ registeredError.exceptionCode }}
      </h1>
      <p>
        {{ localization.saveErrorCode }}
        <i>{{ localization.savedErrorCodeRecipient }} </i>
        <ControlLink
          :label="localization.supportEmail"
          :href="'mailto:' + localization.supportEmail"
        ></ControlLink
        >. {{ localization.sendErrorDetails }}
      </p>
      <HorizontalSeparator class="my-4" />
      <p>{{ localization.errorReturnInstruction }}</p>
      <ControlPanel>
        <ControlButton
          id="back_to_main_page"
          :inverted="false"
          @click="handleError"
        >
          {{ localization.back }}
        </ControlButton>
      </ControlPanel>
    </div>
    <div
      v-if="isDev && registeredError && registeredError.exceptionStackTrace"
      class="w-full overflow-auto text-xs"
    >
      <HorizontalSeparator v-if="error.stack" class="my-4" />
      <pre class="w-full">{{ error.message }}</pre>
      <pre class="w-full" v-html="registeredError.exceptionStackTrace"></pre>
    </div>
    <div v-else-if="isDev" class="w-full overflow-auto p-4 text-xs">
      <HorizontalSeparator v-if="error.stack" class="my-4" />
      <pre class="w-full">{{ error.message }}</pre>
      <pre class="w-full" v-html="error.stack"></pre>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { RegisteredError } from "common-components-nuxt-types/common/error";
import {
  isRegisteredError,
  exceptionFromNuxtError,
} from "./server/utils/error";
import type { NuxtError } from "nuxt/app";
const localization = useGlobalLocalization();

interface Props {
  error: NuxtError<string | { title: string }>;
}
const props = defineProps<Props>();
const registeredError = ref<RegisteredError>();
const isDev = process.env.NODE_ENV !== "production";

const errorData = ref<unknown>();

try {
  if (props.error.data !== undefined && typeof props.error.data === "string") {
    errorData.value = JSON.parse(props.error.data);
  } else {
    errorData.value = props.error.data;
  }
} catch (e) {
  errorData.value = props.error.data;
}

if (!isRegisteredError(errorData.value)) {
  await registerError(props.error);
} else {
  registeredError.value = errorData.value;
}

async function registerError(error: NuxtError) {
  try {
    const errorCodeResponse = await $fetch("/api/error", {
      retry: 0,
      method: "post",
      body: exceptionFromNuxtError(error),
    });
    registeredError.value = {
      exceptionCode: errorCodeResponse,
      registeredException: true,
    };
  } catch (e) {
    registeredError.value = {
      exceptionCode: "#ERR",
      registeredException: false,
    };
  }
}

const handleError = async () => {
  clearNuxtData();
  await clearError({ redirect: "/" });
  reloadNuxtApp({ force: true, persistState: false });
};
</script>
