import validate from "/var/lib/jenkins/workspace/PZO_master/client/node_modules/nuxt/dist/pages/runtime/validate.js";
import _02_45dev_45domain_45global from "/var/lib/jenkins/workspace/PZO_master/client/common-components-nuxt/middleware/02.devDomain.global.ts";
import clear_45status_45result_45global from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/middleware/clearStatusResult.global.ts";
import temporary_45password_45global from "/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/middleware/temporaryPassword.global.ts";
export const globalMiddleware = [
  validate,
  _02_45dev_45domain_45global,
  clear_45status_45result_45global,
  temporary_45password_45global
]
export const namedMiddleware = {
  "01-browser-support": () => import("/var/lib/jenkins/workspace/PZO_master/client/common-components-nuxt/middleware/01.browserSupport.ts"),
  form: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/middleware/form.ts"),
  level: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/middleware/level.ts"),
  login: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/middleware/login.ts"),
  register: () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/middleware/register.ts"),
  "session-student-required": () => import("/var/lib/jenkins/workspace/PZO_master/client/recrutation-parents/middleware/sessionStudentRequired.ts")
}