import type { StatusResult } from "common-components-nuxt-types/common/statusResult";

export async function useAuth(immediate = true) {
  const sessionStore = useSessionStore();
  const { session, initialized } = storeToRefs(sessionStore);
  const { setSession } = sessionStore;
  const userPanelStore = useUserPanelStore();
  const { level } = storeToRefs(userPanelStore);
  const statusResult = useState<StatusResult | null | undefined>(
    "statusResult",
  );
  const loginRequestBodyRef = ref<{ login: string; password: string }>();
  const switchStudentRequestBody = ref<{
    switchToStudentId: number | undefined;
  }>();
  const config = useRuntimeConfig();

  const { data: loginResponse, pending } = await useApi("/api/login", {
    immediate: false,
    method: "post",
    body: loginRequestBodyRef,
  });
  pending.value = false;
  const loginWithPasswordPending = useDebounce(pending, 100);

  const { refresh: logoutApi } = await useApi("/api/user/logout", {
    immediate: false,
  });

  const { data: sessionData, refresh: refreshSessionData } = await useApi(
    "/api/user/sessionData",
    {
      immediate: !initialized.value && immediate,
    },
  );
  if (!initialized.value) {
    setSession(sessionData.value);
  }

  const { data: switchStudentResult, refresh: refreshSwitchStudent } =
    await useApi("/api/user/sessionData/switchStudent", {
      immediate: false,
      retry: 0,
      method: "post",
      body: switchStudentRequestBody,
      watch: false,
    });

  watch(loginResponse, async (newValue) => {
    if (newValue) {
      statusResult.value = newValue;
      if (newValue.type === "SUCCESS") {
        await refreshAuth();
        await navigateTo("/user/loggedIn");
      }
    }
  });

  async function logout(redirectPath = "/login") {
    await logoutApi();
    await refreshAuth();
    clearNuxtData((key) => key.startsWith("/api/user"));
    reloadNuxtApp({
      path: `${config.app.baseURL}${redirectPath}`,
      force: true,
      persistState: false,
    });
  }

  async function pzLogout() {
    const pzLogoutEnabled = userPanelStore.data?.options.pzLogoutEnabled;
    const pzLogoutUrl = userPanelStore.data?.options.pzLogoutUrl;

    if (!pzLogoutEnabled) {
      return;
    }
    const pzLogout = window.open(
      pzLogoutUrl,
      "",
      "width=,height=,resizable=no",
    );
    if (pzLogout) {
      pzLogout.resizeTo(0, 0);
      setTimeout(() => {
        pzLogout.close();
      }, 1000);
    }
  }

  function loginWithPassword(login: string, password: string) {
    loginRequestBodyRef.value = { login, password };
  }

  async function switchStudent(studentId: number, refreshUserPanel = true) {
    switchStudentRequestBody.value = { switchToStudentId: studentId };
    await refreshSwitchStudent();
    if (switchStudentResult.value?.type !== "SUCCESS") {
      statusResult.value = switchStudentResult.value;
      return;
    }
    await refreshAuth();
    if (refreshUserPanel && level.value === defaultLevel.value) {
      await userPanelStore.refresh();
    }
    return goToUserDefaultPage();
  }

  async function refreshAuth() {
    await refreshSessionData();
    setSession(sessionData.value);
  }

  const defaultLevel = computed(() => {
    return session.value?.currentLevel;
  });

  const availableLevels = computed(() => {
    return session.value?.availableLevels;
  });

  const userDefaultPageRoute = computed(() => {
    if (defaultLevel.value !== undefined) {
      return { path: "/user", query: { level: defaultLevel.value } };
    }
  });

  const studentList = computed(() => {
    if (session.value?.connectedStudentList) {
      return session.value.connectedStudentList;
    }
    return null;
  });

  const studentId = computed(() => {
    return session.value?.studentId;
  });

  const student = computed(() => {
    if (session.value?.studentId && session.value?.connectedStudentList) {
      return session.value.connectedStudentList.find(
        (x) => x.studentId == session.value?.studentId,
      );
    }
    return null;
  });

  function goToUserDefaultPage() {
    if (userDefaultPageRoute.value !== undefined) {
      return navigateTo(userDefaultPageRoute.value);
    }
  }

  function goToUserSignPage() {
    if (defaultLevel.value !== undefined) {
      return navigateTo({
        path: "/user/applications",
        query: { level: defaultLevel.value, activePanel: "page" },
      });
    }
  }

  function goToUserQualificationCardPage() {
    if (defaultLevel.value !== undefined) {
      return navigateTo({
        path: "/user/qualificationCard",
        query: { level: defaultLevel.value, activePanel: "page" },
      });
    }
  }

  function goToUserAttachmentsPage() {
    if (defaultLevel.value !== undefined) {
      return navigateTo({
        path: "/user/attachments",
        query: { level: defaultLevel.value },
      });
    }
  }

  const isAuthenticated = computed(() => {
    return session.value !== undefined && session.value !== null;
  });
  const hasApplication = computed(() => {
    return session.value?.hasApplication;
  });
  const isWkSession = computed(() => {
    return session.value?.wkSessionData !== undefined;
  });
  const isMMSession = computed(() => {
    return session.value?.mmSessionData !== undefined;
  });

  const isStudentSelected = computed(() => {
    return session.value?.studentId !== undefined;
  });

  const passwordChangeRequired = computed(() => {
    return session.value?.hasTemporaryPassword === true;
  });

  const loginReady = computed(() => {
    return (
      isAuthenticated.value &&
      session.value !== null &&
      session.value !== undefined &&
      userPanelStore.data !== undefined
    );
  });

  const hasApplicationInOtherModule = computed(() => {
    return session.value?.hasApplicationInOtherModule;
  });

  return {
    loginWithPassword,
    logout,
    pzLogout,
    switchStudent,
    refreshAuth,
    isAuthenticated,
    hasApplication,
    isWkSession,
    isMMSession,
    isStudentSelected,
    passwordChangeRequired,
    session,
    userDefaultPageRoute,
    goToUserDefaultPage,
    goToUserSignPage,
    goToUserAttachmentsPage,
    goToUserQualificationCardPage,
    defaultLevel,
    loginReady,
    loginWithPasswordPending,
    hasApplicationInOtherModule,
    availableLevels,
    studentList,
    studentId,
    student,
  };
}
