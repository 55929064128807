export interface StudentShortDataLocalization {
  loginLabel: string;
  peselLabel: string;
  otherDocumentNoLabel: string;
  firstNameLabel: string;
  lastNameLabel: string;
}

export type StudentShortData = {
  studentId: number;
  login: string;
  pesel: string;
  otherDocumentNo?: string;
  firstName: string;
  lastName: string;
  firstLastName: string;
};

export interface WkSessionData {
  sessionIndex: string;
  nameId: string;
  personIdentifier: string;
  firstName: string;
  lastName: string;
}

export interface MMSessionData {
  pesel?: string;
  userId: string;
  firstName: string;
  lastName: string;
}

export interface SessionData {
  studentId?: number;
  hasApplication: boolean;
  currentLevel?: number;
  availableLevels?: number[];
  connectedStudentList?: StudentShortData[];
  localization: StudentShortDataLocalization;
  wkSessionData?: WkSessionData;
  mmSessionData?: MMSessionData;
  hasTemporaryPassword?: boolean;
  hasApplicationInOtherModule: boolean;
}

export const useSessionStore = defineStore("session", () => {
  const session = ref<SessionData | null | undefined>();
  const initialized = ref(false);

  function setSession(value: SessionData | null | undefined) {
    session.value = value;
    initialized.value = true;
  }

  return {
    session,
    initialized,
    setSession,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSessionStore, import.meta.hot));
}
