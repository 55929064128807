import type { UserPanelButtonCode } from "~/types/user/panelData";
import type { SignRefreshData } from "~/types/user/signData";

export const useUserPanelStore = defineStore("userPanel", () => {
  const level = ref<number>();
  const initialized = ref(false);

  const userPanelRequest = computed(() => {
    if (level.value !== undefined) {
      return {
        id: level.value,
      };
    }
  });

  const activePanel = ref<"page" | "navigation">("navigation");
  const activeUserPage = ref<UserPanelButtonCode>();

  const { data, refresh } = useApi("/api/user/userPanel", {
    method: "post",
    body: userPanelRequest,
    immediate: false,
    watch: false,
  });

  async function initialize(
    initialActivePanel: string | (string | null)[] | null,
  ) {
    if (!initialized.value) {
      await refresh();
      initialized.value = true;
      if (
        initialActivePanel === "navigation" ||
        initialActivePanel === "page"
      ) {
        activePanel.value = initialActivePanel;
      } else {
        activePanel.value = "navigation";
      }
      activeUserPage.value = "details";
    }
  }

  function setLevel(newLevel: number) {
    level.value = newLevel;
  }

  function $reset() {
    data.value = null;
    level.value = undefined;
    initialized.value = false;
    clearNuxtData(
      (key) =>
        key.startsWith("/api/user/userPanel") ||
        key.startsWith("/api/user/notificationsData"),
    );
  }

  function updateSignData(signData: SignRefreshData | null) {
    if (data.value && signData !== null) {
      data.value.motherSign = signData.motherSign;
      data.value.fatherSign = signData.fatherSign;
    }
  }

  watch(level, async (newValue) => {
    if (newValue !== undefined && initialized.value) {
      await refresh();
    }
  });

  return {
    data,
    level,
    $reset,
    setLevel,
    refresh,
    initialize,
    updateSignData,
    activePanel,
    activeUserPage,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserPanelStore, import.meta.hot));
}
