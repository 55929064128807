export default defineNuxtRouteMiddleware(async (to) => {
  if (
    !to.path.startsWith("/user") ||
    to.path === "/user/signApplicationSuccess" ||
    to.path === "/user/signApplicationFailed" ||
    to.path === "/user/signApplicationCancel" ||
    to.path === "/user/signQualificationCardSuccess" ||
    to.path === "/user/signQualificationCardFailed" ||
    to.path === "/user/signQualificationCardCancel"
  ) {
    return;
  }
  const { isAuthenticated, passwordChangeRequired } = await useAuth();
  if (to.path !== "/user/temporaryPassword") {
    if (isAuthenticated.value && passwordChangeRequired.value) {
      return navigateTo("/user/temporaryPassword");
    }
  } else if (
    to.path === "/user/temporaryPassword" &&
    !passwordChangeRequired.value
  ) {
    return navigateTo("/user/loggedIn");
  }
});
