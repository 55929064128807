import type { Ref } from "vue";
import type {
  AvailableRouterMethod as _AvailableRouterMethod,
  NitroFetchRequest,
} from "nitropack";
import type { UseFetchOptions, FetchResult } from "nuxt/app";
import type { FetchError } from "ofetch";
import { setSessionCookies } from "../server/utils/cookie";
import { hash } from "ohash";
import type { AsyncData, KeysOf, PickFrom } from "#app/composables/asyncData";

type AvailableRouterMethod<R extends NitroFetchRequest> =
  | _AvailableRouterMethod<R>
  | Uppercase<_AvailableRouterMethod<R>>;

export function useApi<
  ResT = void,
  ErrorT = FetchError,
  ReqT extends NitroFetchRequest = NitroFetchRequest,
  Method extends AvailableRouterMethod<ReqT> = ResT extends void
    ? "get" extends AvailableRouterMethod<ReqT>
      ? "get"
      : AvailableRouterMethod<ReqT>
    : AvailableRouterMethod<ReqT>,
  _ResT = ResT extends void ? FetchResult<ReqT, Method> : ResT,
  DataT = _ResT,
  PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
  DefaultT = null,
>(
  request: Ref<ReqT> | ReqT | (() => ReqT),
  options?: UseFetchOptions<_ResT, DataT, PickKeys, DefaultT, ReqT, Method>,
): AsyncData<PickFrom<DataT, PickKeys> | DefaultT, ErrorT | null> {
  const config = useRuntimeConfig();
  const requestHeaders = useRequestHeaders(["cookie", "x-forwarded-host"]);
  const event = useRequestEvent();
  const apiOptions = getApiOptions(
    request,
    requestHeaders.cookie,
    requestHeaders["x-forwarded-host"],
    options,
  );
  return useFetch(request, {
    ...apiOptions,
    onResponseError({ response }) {
      const errorData = response._data.data;
      if (errorData && errorData.code === "unauthorized") {
        clearNuxtData((key) => key.startsWith("/api/user"));
        reloadNuxtApp({
          path: `${config.app.baseURL}/unauthorized`,
          force: true,
          persistState: false,
        });
        return;
      }
      if (errorData && errorData.code === "session_timeout") {
        clearNuxtData((key) => key.startsWith("/api/user"));
        reloadNuxtApp({
          path: `${config.app.baseURL}/sessionTimeout`,
          force: true,
          persistState: false,
        });
        return;
      }
      let message = response._data.message;
      if (response.url) {
        message += ` [${response.url}]`;
      }
      message += ` [${getRequestUrl(request)}]`;
      showError({
        fatal: true,
        message: message,
        statusCode: response._data.statusCode,
        statusMessage: response._data.statusMessage,
        data: errorData,
      });
    },
    onResponse({ response }) {
      const responseData = response._data;
      if (process.server && event) {
        setSessionCookies(event, response, Object.keys(config.public.contexts));
      }
      return responseData;
    },
  });
}

export function getApiOptions<
  ResT = void,
  ReqT extends NitroFetchRequest = NitroFetchRequest,
  Method extends
    AvailableRouterMethod<ReqT> = "get" extends AvailableRouterMethod<ReqT>
    ? "get"
    : AvailableRouterMethod<ReqT>,
  _ResT = ResT extends void ? FetchResult<ReqT, Method> : ResT,
  DataT = _ResT,
  PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
  DefaultT = null,
>(
  request: Ref<ReqT> | ReqT | (() => ReqT),
  cookie: string | undefined,
  xForwardedHost: string | undefined,
  options?: UseFetchOptions<_ResT, DataT, PickKeys, DefaultT, ReqT, Method>,
): UseFetchOptions<_ResT, DataT, PickKeys, DefaultT, ReqT, Method> {
  const headers: HeadersInit = {};
  let apiOptions: typeof options = { ...options };
  headers["x-csrf-protection"] = "1";
  if (cookie) {
    headers.cookie = cookie;
  }
  if (xForwardedHost) {
    headers["x-forwarded-host"] = xForwardedHost;
  }
  if (options) {
    apiOptions.headers = headers;
  } else {
    apiOptions = { headers };
  }
  if (!apiOptions.retry) {
    apiOptions.retry = 0;
  }

  if (apiOptions?.key) {
    return apiOptions;
  }
  apiOptions.key =
    getRequestUrl(request) +
    "__" +
    hash([
      unref(
        apiOptions?.method as MaybeRef<string | undefined>,
      )?.toUpperCase() || "GET",
      unref(apiOptions?.baseURL),
      unref(apiOptions?.query || apiOptions?.params),
    ]);

  return apiOptions;
}

function getRequestUrl<ReqT extends NitroFetchRequest = NitroFetchRequest>(
  request: Ref<ReqT> | ReqT | (() => ReqT),
): string {
  let url = "";
  if (typeof request === "function") {
    url = request().toString();
  } else {
    url = unref(request).toString();
  }
  return url;
}
