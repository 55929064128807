import type {
  RegisteredError,
  Exception,
} from "common-components-nuxt-types/common/error";
import type { NuxtError } from "nuxt/app";

export function isRegisteredError(error: unknown): error is RegisteredError {
  const registeredError = error as RegisteredError;
  return (
    registeredError !== undefined &&
    registeredError !== null &&
    registeredError.exceptionCode !== undefined &&
    registeredError.registeredException === true
  );
}

export function isNuxtErrorCheck(error: unknown): error is NuxtError {
  const nuxtError = error as NuxtError;
  return (
    nuxtError.stack !== undefined &&
    (nuxtError.fatal === undefined || nuxtError.fatal)
  );
}

export function exceptionFromNuxtError(error: NuxtError): Exception {
  return {
    exception: error.stack as string,
    exceptionTypeName: "JAVASCRIPT",
    message: error.message ?? error.statusMessage,
  };
}
